@import '../../../assets/styles/foundation/variables';

.app__account {
  &--header {
    display: flex;
    align-items: center;
    padding: 30px;
    border-bottom: 1px solid $border;
    border-bottom: 1px solid var(--border);
  }
  &--initials {
    font-weight: 900;
    background-color: #dbdbdb;
    color: #000000;
    padding: 0;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    font-size: 44px;
    position: relative;
    box-sizing: border-box;
  }
  &--info {
    padding: 0 16px;
  }
  &--name {
    font-size: 24px;
    font-weight: 900;
  }

  &--body {
    padding: 32px;

    > h3 {
      padding: 16px 32px;
    }
  }
  &--form {
    // display: flex;
    // flex-direction: column;
    label {
      display: flex;
      align-items: center;
      min-width: 50%;
      > span {
        margin-right: 16px;
        font-weight: 500;
        font-size: 14px;
        min-width: 75px;
      }
    }
    .app__btn,
    .app__link {
      display: block;
      font-size: 14px;
    }
    .app__btn {
      display: flex;
      align-items: center;
      cursor: pointer;

      > span {
        margin-left: 8px;
      }
    }
    .app__link {
      padding: 0;
      font-weight: 500;
      border: 0;
    }
  }
}
