@import './variables';
*,
*::after,
*::before {
  outline: none !important;
  box-sizing: border-box;
}

html,
body {
  -webkit-tap-highlight-color: transparent;
  overscroll-behavior-y: contain;
  margin: 0;
  padding: 0;
  // font-size: 16px;
  font-size: 1.5em;
  max-height: 100vh;
}

html {
  overflow: hidden;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  font-size: 62.5%;
}

body {
  overflow: hidden;
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  font-weight: 400;
  font-family: $fontPrimary;

  .app__container {
    width: 100%;
    height: 100%;
  }

  .app__layout {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .app__content {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &.isStandalone {
    width: 100vw;
    height: 100%;

    .app__statusbar {
      display: block;
      position: relative;
      top: 0;
      left: 0;
      right: 0;
      height: 23px;
      background: #232323;
      background: $secondary;
      z-index: 10;
    }

    .app__container {
      width: 100vw;
      height: calc(100vh - 23px);
    }
  }

  .fill {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $fontPrimary;
  margin: 0;
}

.defs {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
}

.form-control {
  color: gray;
  font-size: 12px;
  letter-spacing: 1px;
  font-family: $fontPrimary;
}

div.scrollable {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  z-index: 5;
  overscroll-behavior: contain;
  // border-right: 1px solid rgba(0,0,0,.05);
}

div.nonscrollable {
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 5;
}
