@import '../../../assets/styles/foundation/variables';

$form-width: 300px;

#AuthPage {
  background-color: $bg;
  background-color: var(--bg);
}

.app__steps {
  height: 100%;

  // &.app__sliding {
  //   height: auto;
  // }

  &--card {
    width: $form-width;
    margin: 0 auto;
    text-align: center;
    overflow-y: auto;

    &.card-full {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &.steps-center {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      overflow-y: auto;
      height: 100%;
      padding-bottom: 32px;
    }
  }

  &--auth {
    max-width: 200px;
    margin: 0 auto;
    // margin-top: 100px;
  }

  &--logo {
    margin-bottom: 32px;

    &.top-left {
      position: absolute;
      top: 16px;
      left: 16px;
    }
  }
  &--form {
    // width: 100%;
    width: $form-width;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }

  &--footer {
    // position: absolute;
    // bottom: 16px;
    // left:0;
    // right:0;
    text-align: center;
    // transform: translateX(-50%);
    animation-name: fadeUp;
    animation-duration: 1s;
    transition: all 1s ease-in-out;
    margin: 0 auto;
    margin-top: 40px;
    width: 100%;
    max-width: 1200px;

    p {
      margin: 5px 0;
    }
  }

  &--grid {
    width: 100%;

    > h1 {
      letter-spacing: 15px;
      font-style: italic;
      font-size: 36px;
    }
    .app__grid {
      margin-top: 16px;
      display: flex;
      flex-wrap: wrap;
      margin: 16px 64px;
      &--item {
        margin: 16px;
      }
    }
  }

  .app__grid {
    margin-top: 16px;
    grid-gap: 80px;

    .app__grid--item-info,
    .app__grid--item-img {
      cursor: default;
    }
  }

  .app__btn,
  .app__link {
    display: block;
    width: 100%;
    margin-top: 5px;
    padding: 8px 8px;
  }

  .app__btn {
    margin-top: 15px;
  }

  .app__sliding {
    height: 100%;
  }

  // .app__sliding--panel {
  //   width: auto;
  // }

  .app__sliding--inner {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .app__footer {
    &--linkrow {
      display: flex;

      > div {
        flex: 1;
        text-align: center;
        &:first-child {
          text-align: right;
        }
        &:last-child {
          text-align: left;
        }
      }

      &-link {
        padding: 15px 30px;
      }
    }
  }
}

.app__onboarding {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &--logout {
    position: absolute;
    top: 12px;
    right: 12px;
    background-color: transparent;
    text-transform: uppercase;
    border: 1px solid #f2f2f2;
    padding: 8px;
    font-size: 12px;
  }
  .app__steps {
    height: auto;
    &--indicators {
      display: flex;
      width: $form-width;
      justify-content: space-between;
      margin-bottom: 32px;
      &-circle {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-color: transparent;
        border: 1px solid $primary;
        border: 1px solid var(--primary);
        &.active {
          background-color: $primary;
          background-color: var(--primary);
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .app__steps .app__grid {
    justify-content: center;
    align-items: flex-start;
  }
  .app__steps .app__sliding--inner {
    align-items: flex-start;
  }
  .app__steps .app__sliding--panel {
    height: 100%;
  }
  .app__steps--card {
    // max-height: 100vh;
    overflow-y: auto;
    height: 100%;
    // padding-bottom: 32px;
    &.steps-center {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
  .app__steps--auth {
    margin: 32px auto;
  }
  .app__steps--card.card-full {
    // height: auto;
    display: block;
    padding: 32px 0;
  }

  .app__steps--footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
      margin-bottom: 20px;
    }
  }
  .app__footer {
    &--linkrow {
      flex-direction: column;
    }
  }
}

@media screen and (max-width: 768px) {
  #AuthPage {
    .app__topbar {
      position: relative;
      top: 0;
      // border-bottom: 1px solid #000000;
    }
    .app__steps .app__grid {
      display: flex;
      grid-gap: 40px;
      margin-top: 20px;
    }
  }
}
