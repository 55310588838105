@import '../../../assets/styles/foundation/variables';

$explorerTime: 0.35s;

.app__fileexplorer {
  width: 100%;
  overflow: hidden;
  z-index: 20;
  &--panel {
    margin: 0;
    width: 100%;
    float: left;
    overflow: hidden;
    list-style: none;
    padding: 0;
    &-sub {
      margin: 0;
      position: relative;
      float: left;
      width: 100%;
      &:before {
        position: absolute;
        right: 25px;
        width: 10px;
        height: 10px;
        top: 14px;
        z-index: 20;
      }
    }
  }
  &--inner {
    width: 100%;
    overflow: hidden;
  }
  a,
  p,
  &--back,
  &--nav {
    float: left;
    width: 100%;
    padding: 10px;
    position: relative;
    text-align: left;
    background: transparent;
    min-height: 40px;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: -10px;
      width: 0;

      transition: all 300ms linear;
      height: 100%;
    }
    i {
      float: left;
      width: 35px;
      position: relative;
      top: 3px;
    }
    &:hover:after {
      width: 100%;
    }
  }
  &--back {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #bdbdbd;
    > svg {
      margin-right: 5px;
    }
  }

  &--folder,
  &--file,
  &--action,
  &--head {
    float: left;
    text-align: left;
    display: flex;
    padding: 12px;
    appearance: none;
    background-color: transparent;
    width: 100%;
    align-items: center;

    animation-name: fadeIn;
    animation-duration: $explorerTime;

    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: rgba(200, 200, 200, 0.1);
    }

    &-info {
      flex: 1;
      background-color: transparent;
      text-align: left;
      color: $fg;
      color: var(--fg);
    }
    &-actions {
      display: flex;
      position: relative;
    }
    &-action {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;

      transition: all 0.25s ease-in-out;

      svg {
        stroke: $fg;
        stroke: var(--fg);
      }

      &:hover {
        background-color: #f1f1f1;
      }
    }
    &-icon {
      width: 40px;
      height: 40px;
      background: $bg;
      background: var(--bg);
      color: $fg;
      color: var(--fg);
      border: 1px solid $border;
      border: 1px solid var(--border);
      // border-radius: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 15px;
    }
    &-name {
      font-weight: 500;
    }
    &-stats {
      font-size: 12px;

      &.uploading {
        color: $primary;
        color: var(--primary);
      }
      &.waiting {
        color: $c-text-low;
        color: var(--c-text-low);
      }
    }
    &-count {
      flex: 0 0 50px;
    }
  }
  &--file {
    &-action {
      border: 1px solid $border;
      border: 1px solid var(--border);
      padding: 8px 16px;
      color: $fg;
      color: var(--fg);
    }
  }
  &--folder,
  &--action,
  &--head {
    &-action {
      width: 40px;
      height: 40px;
      border-radius: 40px;
    }
  }
  &--action,
  &--head {
    &-icon {
      color: $fg;
      color: var(--fg);
      background: $border;
      background: var(--border);
      border: 1px solid $border;
      border: 1px solid var(--border);

      svg {
        stroke: $fg;
        stroke: var(--fg);
      }
    }
  }
  &--tooltip {
    position: absolute;
    top: 0;
    right: 100%;
    background: white;
    border: 1px solid #ccc;
    &-action {
      min-width: 200px;
      min-height: 40px;
    }
  }

  &--total {
    position: relative;
    width: 48px;
    height: 4px;
    background: #d8d8d8;
    &-progress {
      background: $primary;
      background: var(--primary);
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .app__playpause svg {
    fill: $bg;
    fill: var(--bg);
    stroke: $fg;
    stroke: var(--fg);
  }
}

/* Slide Transition classes  */
$slideTime: 300ms;
.app__fileexplorer--menu-left-enter {
  transform: translateX(-110%);
}
.app__fileexplorer--menu-left-enter-active {
  transform: translateX(0%);
  transition: all $slideTime ease-in-out;
}
.app__fileexplorer--menu-left-exit {
  position: absolute;
}
.app__fileexplorer--menu-left-exit-active {
  transform: translateX(-110%);
  transition: all $slideTime ease-in-out;
}

.app__fileexplorer--menu-right-enter {
  transform: translateX(110%);
}
.app__fileexplorer--menu-right-enter-active {
  transform: translateX(0%);
  transition: all $slideTime ease-in-out;
}
.app__fileexplorer--menu-right-exit {
  position: absolute;
}
.app__fileexplorer--menu-right-exit-active {
  transform: translateX(110%);
  transition: all $slideTime ease-in-out;
}

/* Dropdown Transition classes  */
.app__dropanim-enter {
  max-height: 0px;
  transition: max-height $explorerTime ease;
  overflow: hidden;
}

.app__dropanim-enter.app__dropanim-enter-active {
  height: auto;
  max-height: 100px;
}

.app__dropanim-exit {
  max-height: 100px;
  transition: max-height $explorerTime ease;
}

.app__dropanim-exit.app__dropanim-exit-active {
  overflow: hidden;
  max-height: 0px;
}

:export {
  explorerMillis: $explorerTime;
}
