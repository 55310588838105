.app__dropzone {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  opacity: 0;
  pointer-events: none;
  &--overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba($color: #000000, $alpha: 0.5);
  }

  &--content {
    position: absolute;
    top: 16px;
    left: 16px;
    bottom: 16px;
    right: 16px;
    border: 1px;

    border: 2px dashed #ffffff;
    border-radius: 6px;

    display: flex;
    justify-content: center;
    align-items: center;

    color: #ffffff;
  }

  &--row {
    text-align: center;
  }

  &.active {
    opacity: 1;
    // pointer-events: all;
  }
}
