@import '../../../assets/styles/foundation/variables';

.app__commentinput {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  border: 1px solid transparent;
  border-top: 1px solid $border;
  border-top: 1px solid var(--border);
  z-index: 99;
  position: relative;

  strong {
    color: $primary;
    color: var(--primary);
  }

  .app__miniauthor {
    justify-content: flex-start;
  }

  &--send {
    border: 1px solid transparent;
    &:focus {
      border: 1px solid $border;
      border: 1px solid var(--border);
    }
  }

  svg {
    color: $fg;
    color: var(--fg);
  }
}

.app__commentinput > .left {
  flex: 1;
}

.app__commentinput > .right {
  flex: 0 0 36px;
}

.content {
  height: 36px;
}

.left,
.right {
  display: flex;
  flex-direction: column;
  background-color: $input-bg;
  background-color: var(--input-bg);
}

.left > textarea {
  flex: 1;
  border-radius: 0;
  min-height: $input-height;
  border: none;
  font-size: 14px;
  font-weight: 400;
  transition: all 0.2s ease;
  border-radius: 0;
  text-align: left;

  padding: 14px 16px;
  padding-bottom: 18px;
  background-color: $input-bg;
  background-color: var(--input-bg);
  font-family: $fontPrimary;

  ::placeholder {
    color: $c-text-mid;
    color: var(--c-text-mid);
    font-size: 14px;
    font-weight: 400;
    font-family: $fontPrimary;
  }
}

.right > button {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: $input-bg;
  background-color: var(--input-bg);
}
