//colors
$white: #fff;
$black_10: rgba(0, 0, 0, 0.1);
$black_5: rgba(0, 0, 0, 0.05);
$color_cod_gray_approx: #121212;
$color_silver_chalice_approx: #aaa;
$color_curious_blue_approx: #3498db;
$color_green_approx: #07bc0c;
$color_corn_approx: #f1c40f;
$color_cinnabar_approx: #e74c3c;
$black: #000;
$white_70: rgba(255, 255, 255, 0.7);
$color_heliotrope_approx: #ff9ca0;

//fonts
$familyPrimary: 'Untitled', Poppins, -apple-system, BlinkMacSystemFont, Avenir,
  'Avenir Next', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
  'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

.Toastify__toast-container {
  z-index: 9999;
  position: fixed;
  padding: 4px;
  width: 320px;
  box-sizing: border-box;
  color: $white;
  &--top-left {
    top: 1em;
    left: 1em;
  }
  &--top-center {
    top: 1em;
    left: 50%;
    transform: translateX(-50%);
  }
  &--top-right {
    top: 1em;
    right: 1em;
  }
  &--bottom-left {
    bottom: 1em;
    left: 1em;
  }
  &--bottom-center {
    bottom: 1em;
    left: 50%;
    transform: translateX(-50%);
  }
}

.Toastify__toast {
  position: relative;
  min-height: 64px;
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 10px 16px;
  border-radius: 1px;
  box-shadow: 0 1px 10px 0 $black_10, 0 2px 15px 0 $black_5;
  display: flex;
  justify-content: space-between;
  max-height: 800px;
  overflow: hidden;
  font-family: $familyPrimary;
  cursor: pointer;
  direction: ltr;
  &--rtl {
    direction: rtl;
  }
  &--dark {
    background: $color_cod_gray_approx;
    color: $white;
  }
  &--default {
    background: $white;
    color: $color_silver_chalice_approx;
  }
  &--info {
    background: $color_curious_blue_approx;
  }
  &--success {
    background: $color_green_approx;
  }
  &--warning {
    background: $color_corn_approx;
  }
  &--error {
    background: $color_cinnabar_approx;
  }
  &-body {
    margin: auto 0;
    flex: 1 1 auto;
    white-space: pre-line;
  }
}

.Toastify__close {
  &-button {
    color: $white;
    background: transparent;
    outline: none;
    border: none;
    padding: 0;
    cursor: pointer;
    opacity: 0.7;
    transition: 0.3s ease;
    align-self: flex-start;
    > svg {
      fill: currentColor;
      height: 16px;
      width: 14px;
    }
    &:hover {
      opacity: 1;
    }
    &:focus {
      opacity: 1;
    }
    &--default {
      color: $black;
      opacity: 0.3;
    }
  }
}

.Toastify__toast--default {
  .Toastify__close-button {
    color: $black;
  }
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 100vw;
    padding: 0;
    left: 0;
    margin: 0;
    &--top-left {
      top: 0;
      transform: translateX(0);
    }
    &--top-center {
      top: 0;
      transform: translateX(0);
    }
    &--top-right {
      top: 0;
      transform: translateX(0);
    }
    &--bottom-left {
      bottom: 0;
      transform: translateX(0);
    }
    &--bottom-center {
      bottom: 0;
      transform: translateX(0);
    }
    &--bottom-right {
      bottom: 0;
      transform: translateX(0);
    }
    &--rtl {
      right: 0;
      left: initial;
    }
  }
  .Toastify__toast {
    margin-bottom: 0;
    height: 80px;
  }
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}
.Toastify__progress {
  &-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    z-index: 9999;
    opacity: 0.7;
    background-color: $white_70;
    transform-origin: left;
    &--animated {
      animation: Toastify__trackProgress linear 1 forwards;
    }
    &--controlled {
      transition: transform 0.2s;
    }
    &--rtl {
      right: 0;
      left: initial;
      transform-origin: right;
    }
    &--default {
      background: $black;
    }
    &--dark {
      background: $color_heliotrope_approx;
    }
  }
}

@keyframes Toastify__bounceInRight {
  from {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(10px, 0, 0);
  }
  90% {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(-5px, 0, 0);
  }
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: none;
  }
}
@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}
@keyframes Toastify__bounceInLeft {
  from {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  60% {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }
  75% {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(5px, 0, 0);
  }
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: none;
  }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }
}
@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}
@keyframes Toastify__bounceInUp {
  from {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  75% {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 10px, 0);
  }
  90% {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, -5px, 0);
  }
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }
  40% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }
  45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}
@keyframes Toastify__bounceInDown {
  from {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  60% {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }
  75% {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, -10px, 0);
  }
  90% {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 5px, 0);
  }
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: none;
  }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }
}
@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }
  40% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}
.Toastify__bounce {
  &-enter {
    &--top-left {
      animation-name: Toastify__bounceInLeft;
    }
    &--bottom-left {
      animation-name: Toastify__bounceInLeft;
    }
    &--top-right {
      animation-name: Toastify__bounceInRight;
    }
    &--bottom-right {
      animation-name: Toastify__bounceInRight;
    }
    &--top-center {
      animation-name: Toastify__bounceInDown;
    }
    &--bottom-center {
      animation-name: Toastify__bounceInUp;
    }
  }
  &-exit {
    &--top-left {
      animation-name: Toastify__bounceOutLeft;
    }
    &--bottom-left {
      animation-name: Toastify__bounceOutLeft;
    }
    &--top-right {
      animation-name: Toastify__bounceOutRight;
    }
    &--bottom-right {
      animation-name: Toastify__bounceOutRight;
    }
    &--top-center {
      animation-name: Toastify__bounceOutUp;
    }
    &--bottom-center {
      animation-name: Toastify__bounceOutDown;
    }
  }
}
@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
.Toastify__zoom {
  &-enter {
    animation-name: Toastify__zoomIn;
  }
  &-exit {
    animation-name: Toastify__zoomOut;
  }
}
@keyframes Toastify__flipIn {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    transform: perspective(400px);
  }
}
@keyframes Toastify__flipOut {
  from {
    transform: perspective(400px);
  }
  30% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
.Toastify__flip {
  &-enter {
    animation-name: Toastify__flipIn;
  }
  &-exit {
    animation-name: Toastify__flipOut;
  }
}
@keyframes Toastify__slideInRight {
  from {
    transform: translate3d(110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInLeft {
  from {
    transform: translate3d(-110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInUp {
  from {
    transform: translate3d(0, 110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInDown {
  from {
    transform: translate3d(0, -110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}
@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
}
@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}
@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}
.Toastify__slide {
  &-enter {
    &--top-left {
      animation-name: Toastify__slideInLeft;
    }
    &--bottom-left {
      animation-name: Toastify__slideInLeft;
    }
    &--top-right {
      animation-name: Toastify__slideInRight;
    }
    &--bottom-right {
      animation-name: Toastify__slideInRight;
    }
    &--top-center {
      animation-name: Toastify__slideInDown;
    }
    &--bottom-center {
      animation-name: Toastify__slideInUp;
    }
  }
  &-exit {
    &--top-left {
      animation-name: Toastify__slideOutLeft;
    }
    &--bottom-left {
      animation-name: Toastify__slideOutLeft;
    }
    &--top-right {
      animation-name: Toastify__slideOutRight;
    }
    &--bottom-right {
      animation-name: Toastify__slideOutRight;
    }
    &--top-center {
      animation-name: Toastify__slideOutUp;
    }
    &--bottom-center {
      animation-name: Toastify__slideOutDown;
    }
  }
}
