@import '../../../assets/styles/foundation/variables';

.app__input {
  border-radius: 2px;
  min-height: $input-height;
  border: none;
  font-size: 14px;
  font-weight: 400;
  transition: all 0.2s ease;
  border-radius: 0;
  text-align: left;

  padding: 14px 0;
  background-color: transparent;

  border-bottom: 2px solid $fg;
  border-bottom: 2px solid var(--fg);

  color: $c-text-high;
  color: var(--c-text-high);

  ::placeholder {
    color: $c-text-mid;
    color: var(--c-text-mid);
    font-size: 14px;
    font-weight: 400;
    font-family: $fontPrimary;
  }
}

.app__input-o {
  border-radius: 2px;
  min-height: $input-height;
  border: none;
  font-size: 14px;
  font-weight: 400;
  transition: all 0.2s ease;
  border-radius: 0;
  text-align: left;

  padding: 14px 16px;

  &:not([type='checkbox']) {
    background-color: $border;
    background-color: var(--border);
  }

  ::placeholder {
    color: $c-text-mid;
    color: var(--c-text-mid);
    font-size: 14px;
    font-weight: 400;
    font-family: $fontPrimary;
  }
}
