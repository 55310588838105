@import '../../../assets/styles/foundation/variables';

.app__preview {
  width: 100%;
  display: flex;
  flex-direction: column;

  &--card {
    display: flex;
    padding: 16px;
    border: 1px solid $border;
    border: 1px solid var(--border);
    flex: 1;
    margin-bottom: 16px;
    align-items: center;
  }

  &--play,
  &--loader {
    width: 36px;
    height: 36px;
    margin-right: 16px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border-radius: 50%;
    cursor: pointer;
    > svg {
      fill: $fg;
      fill: var(--fg);
    }
  }

  &--play {
    border: 1px solid $fg;
    border: 1px solid var(--fg);
  }

  &--progress {
    width: 88px;
    height: 2px;
    background: $muted;
    background: var(--muted);
    flex: 1;
    margin-right: 16px;
    position: relative;
    overflow: hidden;
    > div {
      height: 2px;
      width: 0%;
      background: $fg;
      background: var(--fg);
    }
  }

  &--info {
    flex: 1;
    max-width: calc(100% - 140px);

    .metadata-container {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    .author-container {
      margin-right: 6px;
    }

    h5,
    span,
    div,
    small {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &--author {
    font-weight: 500;
  }

  &--isrecording {
    color: $primary;
    color: var(--primary);
  }

  &--actions {
    margin-left: auto;
    display: flex;

    button {
      background-color: transparent;
      cursor: pointer;
      padding: 0;
      width: 40px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      > svg {
        stroke: $fg;
        stroke: var(--fg);
      }
    }
  }
}
