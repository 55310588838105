@import '../../../../assets/styles/foundation/variables';

.app__info {
  position: relative;
  overflow: hidden;

  &--join {
    display: flex;
    padding: 0px 96px;
    text-align: center;
    width: 100%;

    &-btn {
      background-color: #fff;
      padding: 10px;
      width: 100%;
      color: #000;
      text-decoration: none;

      &-untitled {
        color: #ff9ca0;
        font-weight: bold;
      }
    }
  }

  &--back {
    position: absolute;
    top: 12px;
    left: 32px;
    background-color: transparent;

    svg {
      fill: $fg;
      fill: var(--fg);
    }
  }

  &--header {
    position: relative;
    overflow: hidden;

    animation-name: fadeIn;
    animation-duration: 2s;

    &-container {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 80px 96px;
      position: relative;
      border-bottom: 1px solid $border;
      border-bottom: 1px solid var(--border);
    }

    &-thumb {
      flex: 0 0 140px;
      width: 140px;
      height: 140px;
      position: relative;
      border: 1px solid #000000;

      .block-reveal {
        width: 100%;
        height: 100%;
      }

      img {
        width: 138px;
        height: 138px;
        object-fit: cover;
      }
    }

    &-info {
      flex: 1;
      width: calc(100% - 140px);
      padding-left: 60px;
    }

    &-authors {
      display: flex;
      flex-wrap: wrap;
      margin-top: 8px;

      .app__miniauthor {
        margin-bottom: 8px;
      }
    }
  }

  &--blur {
    position: absolute;
    filter: blur(15px);
    height: 110%;
    width: 110%;
    background-color: #ccc;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.2;
    z-index: 0;
  }

  &--body {
    z-index: 1;
    display: flex;
    flex-direction: column;
    padding: 32px 96px;

    animation-name: fadeUp;
    animation-duration: 1s;
  }
}

@media screen and (max-width: 667px) {
  .app__info {
    &--join {
      width: 90%;
      margin: 16px;
      padding: 8px;
      border: 1px solid #000;
      &-btn {
        padding: 0;
      }
    }
    &--body {
      padding: 16px 16px;
    }
    &--header {
      &-container {
        flex-direction: column;
        align-items: flex-start;
        padding: 16px 64px;
        padding-bottom: 0;
        border-bottom: 0;
      }
      &-info {
        width: 100%;
        padding: 8px 0;
        text-align: center;
      }
      &-thumb {
        margin: 0 auto;
      }
      &-authors {
        justify-content: center;
      }
    }
    &--back {
      top: 8px;
      left: 0px;
    }

    &--back {
      top: 8px;
      left: 0px;
    }
  }
}
