@keyframes move {
  47% {
    transform-origin: left;
    animation-mode: forwards;
    transform: scaleX(1);
  }
  50% {
    transform-origin: right;
    transform: scaleX(1);
  }
  100% {
    transform-origin: right;
    transform: scaleX(0);
  }
}

@keyframes reveal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.block-reveal {
  position: relative;
  display: inline-block;
  // line-height: 0;

  &__element {
    opacity: 0;
    animation-duration: 0.01s;
    animation-fill-mode: both;
    display: flex;
  }

  &__block {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    transition: all 0.5s cubic-bezier(0.86, 0, 0.07, 1);
    animation-fill-mode: both;
    transform: scaleX(1);
    transform-origin: left;
  }
}

.animated {
  .block-reveal__element {
    animation-name: reveal;
  }
  .block-reveal__block {
    animation-name: move;
  }
}
