@import '../../../assets/styles/foundation/variables';

$thumbSize: 140px;

.app__grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, $thumbSize + 66px);
  grid-gap: 1rem;
  justify-content: center;
  align-content: flex-start;
  padding: 16px 0;

  &--left {
    justify-content: flex-start;
  }

  &--header {
    position: sticky;
    top: 0;
    background-color: #ffffff;
    margin: 0 auto;
    padding: 32px;
    padding-bottom: 16px;
    z-index: 99;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 744px;
  }

  button {
    cursor: pointer;
    width: 100%;
    * {
      max-width: 100%;
    }
  }

  &--item {
    display: flex;
    flex-direction: column;
    margin: 0;
    align-items: center;
    position: relative;
    text-align: left;

    animation-name: fadeUp;
    animation-duration: 1s;
    padding: 16px 0;

    p {
      margin: 0;
    }

    &-thumb,
    &-add {
      padding: 0;
      margin: 0;
      // border-radius: 7px;
      overflow: hidden;
      border: 1px solid #000;

      button {
        padding: 0;
      }
    }

    &-add {
      background-color: transparent;
      cursor: pointer;
      position: relative;

      > input {
        cursor: pointer;
        outline: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        overflow: hidden;
        filter: alpha(opacity=0); /* IE < 9 */
        opacity: 0;
      }
    }

    &-icon {
      background-color: $fg;
      background-color: var(--fg);
      width: $thumbSize;
      min-width: $thumbSize;
      height: $thumbSize;
      display: flex;
      align-items: center;
      justify-content: center;

      path {
        fill: $bg;
        fill: var(--bg);
      }
    }

    &-bg {
      display: flex;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      // border: 1px solid $fg;
      // border: 1px solid var(--fg);
    }

    &-img {
      display: flex;
      background-color: transparent;
      margin: 0;
      padding: 0;
      padding-bottom: 0;
    }

    &-thumb {
      position: relative;

      img {
        max-height: $thumbSize;
        max-width: $thumbSize;
        object-fit: cover;
        cursor: pointer;
      }

      .app__thumb--icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: transparent;
        opacity: 0.5;

        &:hover {
          opacity: 1;
        }
      }
    }

    &-info {
      width: $thumbSize;
      padding-top: 16px;
      text-align: center;
      background-color: transparent;
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: center;

      text-align: left;
    }

    &-title,
    &-author {
      line-height: 16px;
      margin: 0;
      width: 80%;
      background-color: transparent;
      text-align: left;
      padding: 0;
      color: $c-text-high;
      color: var(--c-text-high);
    }

    &-title {
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 5px;

      &:first-child {
        display: flex;
        justify-content: flex-start;
      }

      .title-pin {
        margin: 0 4px;
        padding: 0;
        flex: 0 0 14px;
      }

      .title-badge {
        width: 12px;
        height: 12px;
        background-color: $primary;
        background-color: var(--primary);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      // svg {
      //   min-width: 14px;
      // }

      &:hover p {
        text-decoration: underline;
      }
    }

    &-author {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
      color: rgba(25, 32, 44, 0.8);
      color: $c-text-mid;
      color: var(--c-text-mid);

      .separator {
        margin: 0 5px;
        color: #9e9e9e;
      }

      time {
        color: $c-text-low;
        color: var(--c-text-low);
      }

      &:hover {
        p,
        span {
          text-decoration: underline;
        }
      }
    }
  }

  .app__progress--circular {
    position: absolute;
    width: 88px;
    height: 88px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media screen and (max-width: 768px) {
  .app__grid {
    justify-content: center;
  }
  .app__grid--header {
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  .app__homebody {
    .app__grid {
      display: flex;
      flex-direction: column;
      padding: 0;
      grid-gap: 0;
      &--item {
        flex-direction: row;
        width: 100%;
        align-items: center;
        padding: 8px;
        border-bottom: 1px solid $border;
        border-bottom: 1px solid var(--border);
        // padding-top: 8px;
        &-thumb img,
        &-icon {
          max-height: 60px;
          max-width: 60px;
          min-width: 60px;
        }
        &-info {
          padding-left: 12px;
          width: calc(100% - 80px);
          padding-top: 0;
        }
        &-thumb,
        &-add {
          flex: 0 0 60px;
        }
      }
      .app__thumb--icon {
        max-height: 60px;
        max-width: 60px;
        min-width: 60px;
        svg {
          max-width: 50%;
        }
      }
    }
  }
}

:export {
  thumbSize: $thumbSize;
}
