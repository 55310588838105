@import '../../../assets/styles/foundation/variables';

$height: 24px;
// TODO: make dynamic height

.app__tabs {
  position: relative;
  display: flex;
  flex-direction: row;
  border: 1px solid $border;
  border: 1px solid var(--border);
  overflow: hidden;
  height: $height;

  button {
    background: transparent;
    &:hover {
      background: rgba(255, 156, 160, 0.1);
    }
  }

  &--tab {
    border-right: 1px solid #f2f2f2;
    &:last-child {
      border-right: 0;
    }
  }

  &--button {
    height: $height;
    font-size: 11px;
    margin-left: 5px;
    border-radius: 0;
    box-sizing: border-box;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #f2f2f2;
    background: #f2f2f2;
    color: #000000;
    padding: 0 5px;

    &:hover {
      background: #000000;
      color: #f5f5f5;
    }
  }

  &--bg {
    position: absolute;
    background: #ff9ca0;
    height: 100%;
    transition: all 0.25s ease-in-out;
    width: 0;
    left: 0;
  }

  &--text {
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.05000000074505806px;
    text-align: center;
    border: 0;
    margin: 0;
    padding: 0 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
