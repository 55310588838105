@import '../../../assets/styles/foundation/variables';

.app__loader {
  // position: absolute;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  background: $bg;
  background: var(--bg);
  transition: all 0.5s ease-in-out;

  &.absolute {
    position: absolute;
  }

  &--inner {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}
