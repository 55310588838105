@import '../../../assets/styles/foundation/variables';

button {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  line-height: 1.5;
  border: 2px solid transparent;
  appearance: none;
  user-select: none;
  border: 0;
  font-family: $fontPrimary;
  // background: transparent;
}

.app__btn,
[type='submit'] {
  background: #000000;
  color: #f5f5f5;
  border-radius: 2px;
  border: none;
  font-size: 1.3rem;
  font-weight: 500;
  transition: all 0.2s ease;
  border: 2px solid transparent;
}
.app__btn.rounded,
[type='submit'].rounded {
  border-radius: 50%;
}
.app__btn.outline,
[type='submit'].outline {
  background: none;
  color: #000000;
  border: 1px solid #000000;
}
.app__btn:hover,
[type='submit']:hover {
  background: #222222;
  color: #f5f5f5;
}
.app__btn.outline:hover,
[type='submit'].outline:hover {
  background: none;
  border: 1px solid #000000;
  color: #000000;
}
.app__btn:focus,
[type='submit']:focus {
  outline: none;
}

.btn {
  min-height: 40px;
  border: none;
  font-weight: 500;
  transition: all 0.2s ease;
  border-radius: 0;
  padding: 10px 16px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  position: relative;
  border: 2px solid transparent;
}

.btn-primary {
  color: #fff;
  background-color: $primary;
  background-color: var(--primary);
  border-color: $primary;
  border-color: var(--primary);

  &:hover {
    color: #fff;
    background-color: $secondary;
    border-color: $secondary;
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 0.2rem rgba(240, 119, 123, 0.5);
  }

  &.disabled,
  &:disabled {
    color: #fff;
    background-color: $primary;
    background-color: var(--primary);
    border-color: $primary;
    border-color: var(--primary);
  }

  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: #fff;
      background-color: $secondary;
      border-color: $terciary;
    }
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 0.2rem rgba(240, 119, 123, 0.5);
    }
  }
}

.btn-secondary {
  color: #fff;
  background-color: #292b2c;
  border-color: #292b2c;

  &:hover {
    color: #fff;
    background-color: #171818;
    border-color: #101112;
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 0.2rem rgba(41, 43, 44, 0.5);
  }

  &.disabled,
  &:disabled {
    color: #fff;
    background-color: #292b2c;
    border-color: #292b2c;
  }

  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: #fff;
      background-color: #101112;
      border-color: #0a0b0b;
    }
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 0.2rem rgba(41, 43, 44, 0.5);
    }
  }
}

.btn-success {
  color: #fff;
  background-color: #24b314;
  border-color: #24b314;

  &:hover {
    color: #fff;
    background-color: #1d9110;
    border-color: #1b850f;
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 0.2rem rgba(36, 179, 20, 0.5);
  }

  &.disabled,
  &:disabled {
    color: #fff;
    background-color: #24b314;
    border-color: #24b314;
  }

  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: #fff;
      background-color: #1b850f;
      border-color: #187a0e;
    }
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 0.2rem rgba(36, 179, 20, 0.5);
    }
  }
}

.btn-info {
  color: #292b2c;
  background-color: #5bc0de;
  border-color: #5bc0de;

  &:hover {
    color: #fff;
    background-color: #3bb4d8;
    border-color: #31b0d5;
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 0.2rem rgba(91, 192, 222, 0.5);
  }

  &.disabled,
  &:disabled {
    color: #292b2c;
    background-color: #5bc0de;
    border-color: #5bc0de;
  }

  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: #fff;
      background-color: #31b0d5;
      border-color: #2aaacf;
    }
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 0.2rem rgba(91, 192, 222, 0.5);
    }
  }
}

.btn-warning {
  color: #292b2c;
  background-color: #f3c74d;
  border-color: #f3c74d;

  &:hover {
    color: #292b2c;
    background-color: #f9da7b;
    border-color: #f9d66f;
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 0.2rem rgba(251, 228, 160, 0.5);
  }

  &.disabled,
  &:disabled {
    color: #292b2c;
    background-color: #f3c74d;
    border-color: #f3c74d;
  }

  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: #292b2c;
      background-color: #f9d66f;
      border-color: #f8d363;
    }
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 0.2rem rgba(251, 228, 160, 0.5);
    }
  }
}

.btn-danger {
  color: #fff;
  background-color: #e65252;
  border-color: #e65252;

  &:hover {
    color: #fff;
    background-color: #e13131;
    border-color: #e02525;
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 0.2rem rgba(230, 82, 82, 0.5);
  }

  &.disabled,
  &:disabled {
    color: #fff;
    background-color: #e65252;
    border-color: #e65252;
  }

  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: #fff;
      background-color: #e02525;
      border-color: #d91f1f;
    }
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 0.2rem rgba(230, 82, 82, 0.5);
    }
  }
}

.btn-light {
  color: #292b2c;
  background-color: #f8f9fa;
  border-color: #f8f9fa;

  &:hover {
    color: #292b2c;
    background-color: #e2e6ea;
    border-color: #dae0e5;
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  }

  &.disabled,
  &:disabled {
    color: #8e8e8e;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
    cursor: not-allowed;
  }

  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: #292b2c;
      background-color: #dae0e5;
      border-color: #d3d9df;
    }
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
    }
  }

  &-outline {
    background-color: transparent;
    color: $c-text-high;
    color: var(--c-text-high);
    border-color: $border;
    border-color: var(--border);

    &:hover {
      border-color: $fg;
      border-color: var(--fg);
    }

    &:focus,
    &.focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
    }

    &.disabled,
    &:disabled {
      background-color: transparent;
      color: $c-text-mid;
      color: var(--c-text-mid);
      border-color: $border;
      border-color: var(--border);
      cursor: not-allowed;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        border-color: $fg;
        border-color: var(--fg);
      }
      &:active:focus,
      &.active:focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(202, 202, 202, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(202, 202, 202, 0.5);
      }
    }
  }
}

.btn-dark {
  color: #fff;
  background-color: $fg;
  background-color: var(--fg);
  border-color: $fg;
  border-color: var(--fg);

  &:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
  }

  &:focus,
  &.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
  }

  &.disabled,
  &:disabled {
    color: #fff;
    background-color: $fg;
    background-color: var(--fg);
    border-color: $fg;
    border-color: var(--fg);
    cursor: not-allowed;
  }

  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: #fff;
      background-color: #1d2124;
      border-color: #171a1d;
    }
    &:active:focus,
    &.active:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
    }
  }

  &-outline {
    background-color: transparent;
    color: $c-text-high;
    color: var(--c-text-high);
    border-color: $fg;
    border-color: var(--fg);

    &:hover {
      color: #fff;
      background-color: #23272b;
      border-color: #1d2124;
    }

    &:focus,
    &.focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
    }

    &.disabled,
    &:disabled {
      background-color: transparent;
      color: $c-text-mid;
      color: var(--c-text-mid);
      border-color: $border;
      border-color: var(--border);
      cursor: not-allowed;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: #fff;
        background-color: #1d2124;
        border-color: #171a1d;
      }
      &:active:focus,
      &.active:focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
      }
    }
  }
}

.btn-outline {
  border: 2px solid #1d2124;
  background: #fff;
  color: #1d2124;
  &.disabled,
  &:disabled {
    background-color: transparent;
    color: $c-text-mid;
    color: var(--c-text-mid);
    border-color: $c-text-mid;
    border-color: var(--c-text-mid);
    cursor: not-allowed;
  }

  &-purple {
    border: 2px solid #595bff;
    background-color: transparent;
    color: #595bff;
  }
}

// Extras

.btn-group-space {
  display: flex;
  justify-content: space-between;
  > .btn {
    flex: 1;
    margin: 0 5px;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
