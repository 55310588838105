@import '../../../assets/styles/foundation/variables';

.app__check {
  border-radius: 2px;
  border: none;
  font-size: 14px;
  font-weight: 400;
  transition: all 0.2s ease;
  border-radius: 0;
  color: $c-text-mid;
  color: var(--c-text-mid);
  text-align: left;
  background-color: transparent;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  input {
    display: none;
  }

  svg {
    margin-right: 5px;
  }

  &.checked {
    color: $bg;
    color: var(--bg);
  }
}
