@import '../../../assets/styles/foundation/variables';

.app__invite {
  padding: 16px;
  &--form {
    display: flex;
    align-items: center;
  }

  &--inputs {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    .app__input {
      margin-bottom: 8px;
      width: 100%;
    }
  }

  &--authors {
    display: flex;
    flex-direction: column;

    // height: 300px;
    // max-height: 40vh;
    // overflow-y: auto;
    margin-top: 8px;
    margin-bottom: 8px;

    &-row {
      display: flex;
      width: 100%;
      justify-content: space-between;
      border-bottom: 1px solid $border;
      border-bottom: 1px solid var(--border);
      margin: 2px 0;
      padding: 12px 0;

      .btn-circle {
        width: 24px;
        height: 24px;
        padding: 0;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .app__miniauthor {
        margin-right: 4px;
        &--initials {
          color: #000000;
          background-color: #f2f2f2;
          font-weight: 700;
          margin-right: 8px;
          width: 32px;
          height: 32px;
          min-width: 32px;
          min-height: 32px;
        }
        &--name {
          font-weight: 700;
        }
      }
    }

    &-actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  &--link {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
    .app__btn {
      display: flex;
      align-items: center;
      padding: 8px 16px;
      color: $primary;
      color: var(--primary);
      border: 1px solid $primary;
      border: 1px solid var(--primary);
      background: transparent;
      cursor: pointer;

      &:hover {
        color: #ffffff;
        background: $primary;
        background: var(--primary);
      }

      span {
        margin-left: 8px;
      }
    }
  }

  .invisible {
    opacity: 0;
  }
}

.app__newwip {
  .app__rows {
    height: 300px;
    max-height: 40vh;
    overflow-y: auto;
    margin-top: 8px;
    margin-bottom: 16px;
  }
}

.app__publicwip {
  &-container {
    display: flex;
    border: 1px solid #c4c4c4;
    margin-top: 4px;
    width: 100%;
  }

  &-linkbox {
    background-color: #f2f2f2;
    border: none;
    color: #828282;
    font-family: $fontPrimary;
    font-size: 12px;
    font-weight: 400;
    padding: 4px;
    width: 80%;
  }

  &-copybtn {
    background-color: #ffffff;
    border-left: 1px solid #c4c4c4;
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    padding: 4px;
    width: 20%;
  }
}
