// Primary
$primary: #ff9ca0;
$secondary: #f8868a;
$terciary: #f0777b;
$bg: #ffffff;
$fg: #000000;
$border: #e9e9e9;
$input-bg: #f1f1f1;
$muted: #e3e3e3;
$overlay: rgba(255, 255, 255, 0.9);

$c-text-low: #c1c1c1;
$c-text-mid: #999999;
$c-text-high: #000000;

$input-height: 40px;

$main: #0356ad;
$success: #24b314;
$info: #5bc0de;
$warning: #f3c74d;
$danger: #e65252;

$fontPrimary: 'Untitled', Poppins, -apple-system, BlinkMacSystemFont, Avenir,
  'Avenir Next', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
  'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

// Urls
$thumb: url('/images/ui/thumb.png');
$thumbSize: 140px;
$thumbSizeSm: 48px;

$time: 0.75s;
$slow-time: 0.5s;
$fast-time: 0.25s;

:export {
  animationMillis: $time;
  animationSlow: $slow-time;
  animationFast: $fast-time;

  primaryColor: $primary;
  secondaryColor: $secondary;

  mainColor: $main;
  successColor: $success;
  dangerColor: $danger;
  infoColor: $info;
  warningColor: $warning;
  lightColor: $bg;
  darkColor: $fg;

  thumbSize: $thumbSize;
  thumbSizeSm: $thumbSizeSm;
}
