.row {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.row > :first-child {
  margin-left: 0;
}

.col {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.col,
[class^='col-'],
[class*=' col-'] {
  margin-left: 4%;
}

.col-1 {
  flex: 1;
}

.col-2 {
  flex: 2;
}

.col-3 {
  flex: 3;
}

.col-4 {
  flex: 4;
}

.col-5 {
  flex: 5;
}

.col-6 {
  flex: 6;
}

.col-7 {
  flex: 7;
}

.col-8 {
  flex: 8;
}

.col-9 {
  flex: 9;
}

.col-10 {
  flex: 10;
}

.col-11 {
  flex: 11;
}

.col-12 {
  flex: 12;
}

@media screen and (max-width: 768px) {
  .col,
  [class^='col-'],
  [class*=' col-'] {
    margin: 1rem 0;
    flex: 0 0 100%;
  }
}
