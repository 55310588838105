@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes blurIn {
  0% {
    filter: blur(60px);
    opacity: 0;
  }

  100% {
    filter: blur(15px);
    opacity: 1;
  }
}

@keyframes fadeLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@keyframes fadeRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@keyframes fadeUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes jumpUp {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5) translate(70px, 70px);
    transform: scale(0.5) translate(70px, 70px);
  }

  80% {
    opacity: 1;
    -webkit-transform: scale(1.1) translate(-10px, -10px);
    transform: scale(1.1) translate(-10px, -10px);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1) translate(0px);
    transform: scale(1) translate(0px);
  }
}
