@import '../../../assets/styles/foundation/variables';

#MainPage {
  position: relative;
  .app__sidebar {
    display: flex;
    flex-direction: column;
    max-width: 100%;
  }
  .app__steps {
    flex: 1;
  }

  .app__home {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;

    &--title {
      font-size: 20px;
      font-style: normal;
      font-weight: 900;
      line-height: 56px;
      letter-spacing: 0px;
      text-align: left;

      margin: 32px 44px;
      margin-bottom: 8px;
    }

    .app__fileexplorer--panel {
      padding: 16px 32px;
    }
  }
}

@media screen and (max-width: 667px) {
  #MainPage {
    .app__home {
      &--header {
        height: auto;
        flex-direction: column;
        align-items: flex-start;
        &-actions {
          width: 100%;
          margin: 8px 0;
          justify-content: center;
        }
        &-search {
          margin-left: 16px;
          margin-right: 16px;
          flex-wrap: wrap;
        }
        &-bubble,
        &-control {
          margin-right: 4px;
        }
        &-tab {
          margin-left: 8px;
          margin-right: 8px;
          &:first-child {
            margin-left: 18px;
          }
        }
      }

      .app__fileexplorer--panel {
        padding: 0;
      }
    }
  }
}
