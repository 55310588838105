@import '../../../assets/styles/foundation/variables';

.app__track {
  display: flex;
  flex-direction: column;
  padding: 12px;

  &--item {
    display: flex;
    width: 100%;
    align-items: center;
    position: relative;
    text-align: left;

    &-control {
      width: 60px;
      min-width: 60px;
      height: 60px;
      flex: 0 0 60px;
      position: relative;
      padding: 0;
      margin: 0;
      cursor: pointer;
      background-color: transparent;
    }

    &-thumb {
      width: 60px;
      min-width: 60px;
      height: 60px;
      flex: 0 0 60px;
      background-image: $thumb;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      padding: 0;
      margin: 0;
      cursor: pointer;

      button {
        padding: 0;
        background-color: transparent;
      }

      img {
        width: 60px;
        height: 60px;
        max-height: 60px;
        max-width: 60px;
        object-fit: cover;
      }

      .app__thumb--play {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.5;
        width: 60px;
        height: 60px;
      }

      &:hover {
        .app__thumb--play {
          opacity: 1;
        }
      }
    }

    &-title {
      line-height: 1.5;
    }

    &-info {
      width: calc(100% - 60px);
      flex: 1;
      // max-width: 320px;
      padding: 0 12px;
      text-align: left;
      cursor: pointer;
      background-color: transparent;

      color: $c-text-high;
      color: var(--c-text-high);

      height: 100%;
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &-title,
    &-author {
      margin: 0;
      width: 100%;
    }

    &-author {
      color: $c-text-mid;
      color: var(--c-text-mid);
    }
  }
}
