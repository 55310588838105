@import '../../../assets/styles/foundation/variables';

.mentions {
  // margin: 1em 0;
  z-index: 9;

  strong {
    color: transparent;
    background-color: $primary;
    background-color: var(--primary);
  }
}

.mentions--singleLine .mentions__control {
  display: inline-block;
}
.mentions--singleLine .mentions__highlighter {
  padding: 1px;
  border: 2px inset transparent;
}
.mentions--singleLine .mentions__input {
  padding: 5px;
  border: 2px inset;
}

.mentions--multiLine .mentions__control {
  font-family: $fontPrimary;
  font-size: 14pt;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: 400;
}
.mentions--multiLine .mentions__highlighter {
  padding: 9px;
}
.mentions--multiLine .mentions__input {
  padding: 9px;
  min-height: 63px;
  outline: 0;
  border: 0;
  border: 2px solid transparent;
}

.mentions__suggestions__list {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 10pt;
}

.mentions__suggestions__item {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  text-align: left;
}

.mentions__suggestions__item--focused {
  background-color: #f1f1f1;
}

.mentions__mention {
  // background-color: #f1f1f1;
  color: transparent;
  background-color: $primary;
  background-color: var(--primary);
}

@media screen and (max-width: 768px) {
  .mentions__suggestions {
    height: 1px;
    overflow: visible;
    width: 90%;
  }

  .mentions__suggestions__list {
    left: 0;
    bottom: 100%;
    position: absolute;
    width: 90%;
  }

  .mentions__highlighter {
    margin-top: 1px;
    margin-left: -2px;
  }
}
