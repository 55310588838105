@import '../foundation/variables';

:root {
  --bg: #ffffff;
  --fg: #000000;
  --pink: #ff9ca0;
  --primary: #ff9ca0;
  --secondary: #f8868a;
  --terciary: #f0777b;
  --red: #ff5a60;
  --green: #28a86d;
  --blue: #587cff;
  --border: #e9e9e9;
  --c-text-low: #c1c1c1;
  --c-text-mid: #5c5c5c;
  --c-text-high: #000000;
  --input-bg: #f1f1f1;
  --overlay: rgba(255, 255, 255, 0.9);
  --muted: #e3e3e3;
}

body {
  background-color: $bg;
  background-color: var(--bg);
  color: $c-text-high;
  color: var(--c-text-high);
}

[color-scheme='dark'] {
  color-scheme: dark;
  --bg: #424242;
  --fg: #ffffff;
  --pink: #ff9ca0;
  --primary: #ff9ca0;
  --secondary: #f8868a;
  --terciary: #f0777b;
  --red: #ff5a60;
  --green: #28a86d;
  --blue: #587cff;
  --border: #343434;
  --c-text-low: #747474;
  --c-text-mid: #9f9f9f;
  --c-text-high: #ffffff;
  --input-bg: #323232;
  --overlay: rgba(0, 0, 0, 0.9);
  --muted: #323232;
}

// Util
.capitalize {
  text-transform: capitalize;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.relative {
  position: relative;
}

.inline {
  display: inline-block;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-center {
  align-items: center;
  justify-content: center;
}

.flex-start {
  justify-content: flex-start;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-1 {
  flex: 1;
}

.space-between {
  justify-content: space-between;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-primary {
  color: $primary;
  color: var(--primary);
}

.text-muted {
  color: rgba(25, 32, 44, 0.5);
}

// App
.app__group {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.app__link {
  font-size: 1.3rem;
  font-weight: 500;
  background: transparent;
}

.app__title {
  font-weight: 900;
}

.app__floating {
  position: absolute;
}

// App

.app__topback {
  position: absolute;
  top: 32px;
  left: 32px;
  z-index: 99;
}

.app__miniauthor {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  background-color: transparent;
  padding: 0;
  &--initials,
  &--add {
    font-weight: 700;
    background-color: #dbdbdb;
    color: #000000;
    padding: 0;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    font-size: 12px;
    position: relative;
    box-sizing: border-box;

    &.light {
      background-color: #f2f2f2;
      border: 1px solid #e4e4e4;
    }

    &.dark {
      color: #dbdbdb;
      background-color: #000000;
    }

    &.active {
      border-color: $primary;
      border-color: var(--primary);
    }

    &.outline {
      background-color: transparent;
      border: 1px solid $fg;
      border: 1px solid var(--fg);

      svg {
        color: $fg;
        color: var(--fg);
      }

      &:hover {
        background-color: $bg;
        background-color: var(--bg);
      }
    }

    span {
      line-height: 0;
    }
  }

  &--add {
    cursor: pointer;
    margin-right: 0;
  }

  &--name {
    font-size: 14px;
  }
}

.app__rows {
  display: flex;
  flex-direction: column;

  &--item {
    display: flex;
    width: 100%;
    justify-content: space-between;
    background: transparent;
    border-bottom: 1px solid $border;
    border-bottom: 1px solid var(--border);
    margin: 2px 0;
    padding: 8px 4px;
    min-height: 48px;

    &-info {
      max-width: calc(100% - 32px);
      text-align: left;

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &.current {
      background-color: rgba(200, 200, 200, 0.1);
    }
  }

  &--info-title,
  &--info-author {
    display: block;
  }

  &--info-title {
    font-weight: 700;
  }
}

.app__page {
  .app__grid {
    margin-bottom: 96px;
    max-width: 100%;
  }
}

.app__dropdown {
  &--item {
    white-space: nowrap;
    padding: 8px 0;
    margin-bottom: 4px 0;
    border-bottom: 1px solid #ccc;

    &:last-child {
      border-bottom: 0;
    }
  }
}

.app__steps .app__sliding--panel {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.app__user--link {
  cursor: pointer;
  color: $primary;
  color: var(--primary);
}

.app__miniplaypause {
  padding: 0;
  width: 32px;
  height: 32px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  display: inline-block;
  .app__playpause--play {
    width: 32px;
    height: 32px;

    svg {
      fill: $fg;
      fill: var(--fg);
    }
  }
}

.app__commentbar {
  display: flex;
  padding: 8px;
  border-top: 1px solid $border;
  border-top: 1px solid var(--border);
  > input {
    flex: 1;
  }
  > button {
    flex: 0 0 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $input-bg;
    background-color: var(--input-bg);
  }
}

.app__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .app__steps {
    .app__grid {
      padding: 16px 48px;
    }
    .app__sliding--panel {
      padding: 0;

      .app__steps--grid {
        padding: 24px;
      }
    }
  }
}
