@import '../../../assets/styles/foundation/variables';

.app__comments {
  padding: 16px;
  display: flex;
  flex-direction: column;
  &--item {
    margin: 8px 0;
    display: flex;
    line-height: 24px;
  }

  &--info {
    background: $input-bg;
    background: var(--input-bg);
    padding: 8px 12px;
    border-radius: 8px;
    border-top-left-radius: 2px;
    &-content {
      b {
        font-weight: 500;
      }
      time {
        color: $c-text-low;
        color: var(--c-text-low);
      }
    }
  }

  .app__miniauthor--initials {
    flex: 0 0 32px;
    width: 32px;
    height: 32px;
  }
}
