@import '../../../assets/styles/foundation/variables';

.app__playpause {
  padding: 0;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 0;
  outline: 0;
  // display: inline-block;

  &--play {
    display: flex;
  }

  svg {
    fill: $fg;
    fill: var(--fg);
  }
}
