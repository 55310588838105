$slideTime: 300ms;

.app__sliding {
  min-height: 262px;
  width: 100%;
  height: 100%;
  position: relative;
  // overflow: hidden;
  z-index: 20;
  &--panel {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    float: left;
    // overflow: hidden;
    > li {
      appearance: none;
      display: block;
    }
  }
  &--inner {
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: height $slideTime ease-in-out;
  }
}

/* CSSTransition classes  */
.app__sliding--menu-left-enter {
  transform: translateX(-110%);
  opacity: 0;
}
.app__sliding--menu-left-enter-active {
  transform: translateX(0%);
  opacity: 1;
  transition: transform $slideTime ease-in-out, opacity $slideTime ease;
}
.app__sliding--menu-left-exit {
  position: absolute;
}
.app__sliding--menu-left-exit-active {
  transform: translateX(-110%);
  opacity: 0;
  transition: transform $slideTime ease-in-out, opacity $slideTime ease;
}
.app__sliding--menu-left-exit-done {
  display: none;
}

.app__sliding--menu-right-enter {
  transform: translateX(110%);
  opacity: 0;
}
.app__sliding--menu-right-enter-active {
  transform: translateX(0%);
  opacity: 1;
  transition: transform $slideTime ease-in-out, opacity $slideTime ease;
}
.app__sliding--menu-right-exit {
  position: absolute;
}
.app__sliding--menu-right-exit-active {
  transform: translateX(110%);
  opacity: 0;
  transition: transform $slideTime ease-in-out, opacity $slideTime ease;
}
.app__sliding--menu-right-exit-done {
  display: none;
}

@media screen and (max-width: 768px) {
  .app__sliding {
    min-height: auto;
    max-height: auto;
  }
}

:export {
  slideTime: $slideTime;
}
