@import '../../../assets/styles/foundation/variables';

$width: 460px;
$menuTime: 0.3s;

.app__menu {
  position: absolute;
  z-index: 9;
  &--overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    background: $overlay;
    background: var(--overlay);

    opacity: 0;
    z-index: 0;
    transition: all $menuTime cubic-bezier(1, 0.89, 1, 0.5);
    &-opened {
      opacity: 0.8;
      z-index: 98;
      transition: all $menuTime cubic-bezier(0.5, 1, 0.89, 1);
    }
    &-tooltip {
      width: 150px;
      height: 50px;
      line-height: 50px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 2px;
      z-index: 100;
      display: none;
    }
    &:hover .app__menu--overlay-tooltip {
      display: block;
      position: fixed;
      overflow: hidden;
    }
  }
  &--holder {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 99;
    width: $width;
    background: $bg;
    background: var(--bg);
    overflow: hidden;
    padding: 0;
    border-left: 1px solid $border;
    border-left: 1px solid var(--border);

    right: -100%;
    opacity: 0;
    transition: all $menuTime cubic-bezier(1, 0.89, 1, 0.5);

    display: flex;
    flex-direction: column;

    max-height: 100vh;
    &-opened {
      right: 0;
      opacity: 1;
      transition: all $menuTime cubic-bezier(0.5, 1, 0.89, 1);
    }
    &:after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      width: 50%;
      height: 80px;
      z-index: -1;
    }
  }
  &--logo {
    float: left;
    position: absolute;
    top: 60px;
    left: 170px;
    display: flex;
    align-items: center;
    &:before {
      content: '';
      position: absolute;
      right: -100px;
      left: 90px;
      top: -10px;
      bottom: -13px;
      z-index: -1;

      transition: all 300ms linear;
    }
    img {
      height: 18px;
      width: auto;
    }
    &:hover:before {
      left: calc(100% + 100px);
    }
  }
  &--title {
    position: absolute;
    bottom: 0;
    left: 120px;
    z-index: 2;
    right: 0;
    float: left;
    span {
      float: left;
      position: relative;
      opacity: 0.2;
      top: 150px;
      min-height: 200px;
      text-transform: uppercase;
      font-weight: 300;
      font-size: 94px;
      letter-spacing: 4px;

      transform: rotate(-90deg);

      transform-origin: left top;
    }
    &-action {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      background: transparent;
    }
  }
  &--wrap {
    position: relative;

    div.scrollable {
      max-height: 100vh;
    }
  }
  &--header {
    height: 85px;
    border-bottom: 1px solid $border;
    border-bottom: 1px solid var(--border);
    display: flex;
    align-items: center;
    background: $bg;
    background: var(--bg);
    z-index: 9;
    &-title {
      padding-left: 8px;
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: 56px;
      letter-spacing: 0px;
      text-align: left;
      user-select: none;
      max-width: calc(100% - 54px);
      display: flex;
      align-items: center;
    }
    &-close {
      position: absolute;
      top: 34px;
      right: 20px;
      background: transparent;
      user-select: none;

      svg {
        fill: $fg;
        fill: var(--fg);
      }
    }
    .app__miniplaypause {
      margin-right: 4px;
      flex: 0 0 32px;
    }
  }
  &--footer {
    display: flex;
    padding: 8px;
    border-top: 1px solid $border;
    border-top: 1px solid var(--border);
    animation-name: fadeUp;
    animation-duration: 0.5s;
  }
}

@media only screen and (max-width: 1064px) {
  .app__menu {
    &--title {
      display: none;
    }
  }
}

@media only screen and (max-width: 768px) {
  .app__menu {
    &--holder {
      width: 100%;
      overflow-x: hidden;
    }
    &--logo {
      left: 30px;
    }
    &--header {
      height: 55px;
      position: sticky;
      top: 0;
      &-title {
        font-size: 18px;
      }
      &-close {
        top: 20px;
        right: 20px;
      }
    }
  }
}

.isIE {
  .app__menu--title {
    bottom: 10px;
    span {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      float: none;
      top: -20px;
    }
  }
}

// Tansition
.transition-menu-appear {
  animation-duration: $menuTime;
  &.transition-menu-appear-active {
    animation-name: transition-fadeIn;
    animation-duration: $menuTime;
    animation-timing-function: ease-in-out;
    visibility: visible !important;
  }
}

.transition-menu-enter {
  opacity: 0;
  transition: all $menuTime ease-in-out;
  &.transition-menu-enter-active {
    opacity: 1;
    transition: all $menuTime ease-in-out;
  }
}

.transition-menu-exit {
  opacity: 1;
  transition: all $menuTime ease-in-out;
  &.transition-menu-exit-active {
    opacity: 0;
    transition: all $menuTime ease-in-out;
  }
}

:export {
  menuMillis: $menuTime;
}
