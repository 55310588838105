body.not-mobile {
  ::-webkit-scrollbar {
    width: 0.85em; /* Total width including `border-width` of scrollbar thumb */
    height: 0;
  }
  ::-webkit-scrollbar-thumb {
    height: 0.5em;
    border: 0.25em solid rgba(0, 0, 0, 0); /* Transparent border together with `background-clip: padding-box` does the trick */
    border-left: 0.35em solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0.15);
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.025);
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }
  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  ::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.2);
    // box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-left: 1px solid rgba(0, 0, 0, 0.05);
    border-right: 1px solid rgba(0, 0, 0, 0.05);
  }
}

body.color-scheme-dark {
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
  }
  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.2);
  }
}
