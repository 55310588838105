@import '../../../assets/styles/foundation/variables';

.app__progress--circular {
  width: 100%;
  height: 100%;
  circle {
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    stroke: $fg;
    stroke: var(--fg);
    &.circle {
      stroke: $fg;
      stroke: var(--fg);
    }
    &.circle-bg {
      stroke: $muted;
      stroke: var(--muted);
    }
  }
}
