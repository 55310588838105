@import '../../../assets/styles/foundation/variables';

.app__controls {
  display: flex;
  flex-direction: column;
  width: 100%;

  &--main,
  &--time {
    display: flex;
  }

  &--time {
    width: 100%;
    margin-top: -4px;
    justify-content: space-between;
    font-size: 12px;

    &-elapsed {
      text-align: left;
      flex: 1;
    }

    &-duration {
      text-align: right;
      flex: 1;
    }
  }

  &--main {
    justify-content: center;
    align-items: center;
    &-btn {
      margin: 0 2%;
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: 0;
      background-color: transparent;

      border-radius: 50%;

      &.light {
        background-color: $bg;
        background-color: var(--bg);
      }

      &.selected,
      &:hover {
        background-color: hsla(0, 0%, 60.4%, 0.3);
      }

      svg {
        fill: $fg;
        fill: var(--fg);
      }
    }

    .control-main {
      background-color: $fg;
      background-color: var(--fg);
      width: 36px;
      height: 36px;
      border-radius: 50%;

      svg {
        fill: var(--bg);
      }
    }

    .control-outline {
      border: 1px solid $fg;
      border: 1px solid var(--fg);
      background-color: transparent;
      width: 36px;
      height: 36px;
      border-radius: 50%;

      svg {
        fill: $fg;
      }

      &:hover {
        background-color: hsla(0, 0%, 60.4%, 0.3);
      }

      &.selected {
        background-color: #000;

        > svg {
          fill: #fff;
          background-color: #000;
        }
      }
    }
  }
}
