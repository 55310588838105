@import '../../../assets/styles/foundation/variables';

.app__playbar {
  display: flex;
  align-items: center;
  z-index: 98;
  position: relative;

  svg {
    fill: $fg;
    fill: var(--fg);
  }

  // &--loader {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background-color: rgba(227, 227, 227, 0.95);
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   // animation-name: fadeRight;
  //   // animation-duration: 0.5s;

  //   &-info {
  //     display: flex;
  //     flex-direction: column;
  //     align-items: center;
  //     width: 100%;
  //   }

  //   &-bar {
  //     margin-top: 4px;
  //     height: 2px;
  //     background-color: #000000;
  //   }
  // }

  &--close {
    position: absolute;
    top: 0;
    right: 0;
    background: transparent;
  }

  &--audio {
    height: 80px;
    width: 100%;
    flex: 1;
    padding: 10px;
    background-color: $muted;
    background-color: var(--muted);
    border-top: 1px solid $border;
    border-top: 1px solid var(--border);

    display: flex;
    align-items: center;
    animation-name: fadeUp;
    animation-duration: 1s;

    &-info,
    &-volume {
      flex: 1;
      display: flex;
      justify-content: center;
      flex: 0 0 30%;
      width: 30%;
    }

    &-controls {
      flex: 2;
      display: flex;
      justify-content: center;
    }

    &-volume {
      display: flex;
      justify-content: flex-end;
      > svg {
        margin-right: 5px;
      }

      .app__range {
        margin: 0 4px;
      }
    }
  }

  &--action {
    width: 80px;
    height: 80px;
    background-color: #000000;
    padding: 0;
    cursor: pointer;

    animation-name: fadeUp;
    animation-duration: 1s;

    box-shadow: 0 4px 16px 4px rgba(120, 120, 120, 0.2);
  }

  .app__track--item-control {
    display: none;
  }
}

.is-mobile {
  .app__playbar--audio-controls,
  .app__playbar--audio-volume {
    display: none;
  }
  .app__playbar--audio-info {
    flex: 1;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .app__playbar {
    .app__track--item-control {
      display: block;
    }
  }
  .app__playbar--audio {
    height: 62px;
    padding: 0;
  }
  .app__playbar--audio-controls,
  .app__playbar--audio-volume {
    display: none;
  }
  .app__playbar--audio-info {
    flex: 1;
    width: 100%;
  }
  .app__track--item-info {
    flex: 1;
    width: calc(100% - 120px);
  }
}
