@import '../../../assets/styles/foundation/variables';

$thumbSize: 322px;

.app__upload {
  &--form {
    display: flex;
    align-items: center;
  }

  &--thumb {
    width: $thumbSize;
    height: $thumbSize;
    border: 1px solid #000000;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    // padding: 8px;
    background-image: $thumb;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 0 auto;
    // margin-bottom: 16px;
    position: relative;

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    > button,
    > label {
      background-color: rgba(0, 0, 0, 0.5);
      color: #ffffff;
      border: 0;
      padding: 4px 8px;
      font-size: 12px;
      text-align: center;
      position: absolute;
      bottom: 8px;
      left: 50%;
      transform: translateX(-50%);
      width: 80px;
    }
  }

  // &--form {
  //   margin: 16px 0;
  // }

  &--inputs {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    .app__input {
      margin-bottom: 8px;
      width: 90%;
    }
  }

  &--authors {
    display: flex;
    flex-direction: column;

    &-heading {
      margin-bottom: 16px;
      > button {
        display: flex;
        align-items: center;
        background-color: transparent;
      }
    }

    .app__miniauthor--add {
      margin-left: 8px;
    }

    .app__miniauthor {
      margin-bottom: 8px;
      justify-content: start;
      padding: 0 4px;
    }
  }

  &--tabs {
    display: flex;
    align-items: center;
    width: 100%;

    &-btn {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: left;
      background-color: transparent;
      color: $c-text-mid;
      color: var(--c-text-mid);

      &.selected {
        color: $fg;
        color: var(--fg);
      }
    }
  }

  &--wips {
    .app__rows--item {
      background-color: transparent;
      align-items: center;
      border-bottom: 1px solid $border;
      border-bottom: 1px solid var(--border);
    }
    .app__rows--item-info {
      text-align: left;
    }
  }

  &--footer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &-action {
      flex: 1;
      width: 100%;
    }
  }

  &--head {
    width: 90%;
    text-align: left;
    margin-bottom: 15px;
  }

  &--sep {
    width: 90%;
  }
}

:export {
  thumbSize: $thumbSize;
}
