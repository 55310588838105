@import '../../../assets/styles/foundation/variables';

$font-size: 14px;

.app__activity {
  padding: 16px;

  h5 {
    margin-bottom: 16px;
  }

  time {
    color: $c-text-low;
    color: var(--c-text-low);
  }

  &--invites {
    margin-bottom: 32px;

    &-item {
      margin-bottom: 8px;
    }
  }

  &--notifications {
    &-item {
      padding-right: 16px;
      margin-bottom: 24px;
      display: flex;
      flex-direction: row;

      font-size: $font-size;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;

      b {
        font-weight: 500;
      }

      .app__miniauthor--initials {
        min-width: 32px;
        min-height: 32px;
        margin-right: 8px;
      }
    }
    &-info {
      display: flex;
      align-items: center;
    }
  }
}

.app__invites {
  display: flex;
  align-items: center;

  font-size: $font-size;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;

  &--thumb {
    flex: 0 0 50px;
    width: 50px;
    height: 50px;
    background-size: cover;
    background-repeat: no-repeat;
  }
  &--info {
    padding: 0 8px;
    width: 180px;
    flex: 1;

    &-title {
      font-weight: 600;
      max-width: 200px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &-author {
      display: flex;
      font-weight: 400;
    }
  }
  &--hyphen {
    margin: 0 4px;
    color: rgba(25, 32, 44, 0.5);
  }

  &--actions {
    display: flex;
    flex-direction: column;

    .app__btn {
      font-weight: 400;
      background-color: transparent;
      border: 1px solid $c-text-high;
      border: 1px solid var(--c-text-high);
      color: $c-text-high;
      color: var(--c-text-high);
      font-size: 11px;
      padding: 0 6px;
      margin-left: 5px;
      border-radius: 0;
      box-sizing: border-box;
      cursor: pointer;

      &:hover {
        background-color: $muted;
        background-color: var(--muted);
      }

      &:first-child {
        margin-bottom: 2px;
        background-color: $primary;
        background-color: var(--primary);
        color: $bg;

        &:hover {
          background-color: $secondary;
          background-color: var(--secondary);
        }
      }
    }
  }
}

.app__notification {
  &--item {
    &-content {
      color: $c-text-mid;
      color: var(--c-text-mid);
      b {
        color: $c-text-high;
        color: var(--c-text-high);
      }
    }
    &-title {
      cursor: pointer;
    }
  }
}
