@import './variables';

@keyframes transition-fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes transition-fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.transition-fade-appear {
  &.transition-fade-appear-active {
    animation-name: transition-fadeIn;
    animation-duration: $time;
    animation-timing-function: ease-in-out;
    visibility: visible !important;
  }
}

.transition-fade-enter {
  opacity: 0;
  transition: all $time ease-in-out;
  &.transition-fade-enter-active {
    opacity: 1;
    transition: all $time ease-in-out;
    position: absolute;
    width: 100%;
  }
  &-done {
    opacity: 1;
  }
}

.transition-fade-exit {
  opacity: 1;
  transition: all $time ease-in-out;
  &.transition-fade-exit-active {
    opacity: 0;
    transition: all $time ease-in-out;
  }
}

// Fast
.transition-fast-appear {
  animation-duration: 200ms;
  &.transition-fast-appear-active {
    animation-name: transition-fadeIn;
    animation-duration: 200ms;
    animation-timing-function: ease-in-out;
    visibility: visible !important;
  }
}

.transition-fast-enter {
  opacity: 0;
  transition: all 200ms ease-in-out;
  &.transition-fast-enter-active {
    opacity: 1;
    transition: all 200ms ease-in-out;
  }
}

.transition-fast-exit {
  opacity: 1;
  transition: all 200ms ease-in-out;
  &.transition-fast-exit-active {
    opacity: 0;
    transition: all 200ms ease-in-out;
  }
}

// Slow
.transition-slow-appear {
  animation-duration: 500ms;
  &.transition-slow-appear-active {
    animation-name: transition-fadeIn;
    animation-duration: 500ms;
    animation-timing-function: ease-in-out;
    visibility: visible !important;
  }
}

.transition-slow-enter {
  opacity: 0;
  transition: all 500ms ease-in-out;
  &.transition-slow-enter-active {
    opacity: 1;
    transition: all 500ms ease-in-out;
  }
}

.transition-slow-exit {
  opacity: 1;
  transition: all 500ms ease-in-out;
  &.transition-slow-exit-active {
    opacity: 0;
    transition: all 500ms ease-in-out;
  }
}
