@import '../../../assets/styles/foundation/variables';

#PreviewPage {
  position: relative;
  background-color: $bg;
  background-color: var(--bg);

  .app__home {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;

    &--title {
      font-size: 20px;
      font-style: normal;
      font-weight: 900;
      line-height: 56px;
      letter-spacing: 0px;
      text-align: left;
      margin: 32px 44px;
      margin-bottom: 8px;
    }
  }

  .app__menu--footer {
    justify-content: center;
  }
}
