@import '../../../assets/styles/foundation/variables';

.modalpicker {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  justify-content: center;
  // align-items: center;
  z-index: 999;
  .scrollpicker__main {
    margin: 0;
  }
  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    border: 0;
    > span {
      opacity: 0;
    }
  }
  &__wrapper {
    max-width: 600px;
    min-width: 300px;
    margin: 0 auto;
    border-radius: 24px;
    overflow: hidden;
    background: $bg;
    background: var(--bg);
    // padding: 24px;
  }
  &__panel {
    max-width: 324px;
  }
  &__confirm {
    display: flex;
    flex-direction: column;
    text-align: center;

    h3 {
      margin-bottom: 16px;
      padding: 24px;
      padding-bottom: 0;
    }

    button {
      margin: 0;
      padding: 12px 24px;
      background: transparent;
      border-top: 1px solid $border;
      border-top: 1px solid var(--border);
      color: #14bdf2;
      &:last-child {
        color: #df4748;
      }
    }
  }
}

@media (max-width: 768px) {
  .modalpicker {
    justify-content: flex-end;
    &__wrapper {
      min-width: 100%;
      margin: 0 auto;
      border-radius: 0;
    }
    &__panel {
      min-width: 100%;
      max-width: auto;
    }
  }
}
