@import '../../../assets/styles/foundation/variables';

.app__header {
  height: 85px;
  min-height: 85px;
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $border;
  border-bottom: 1px solid var(--border);
  &-logo {
    margin: 0 26px;
    background: transparent;
  }
  &-tabs {
    display: flex;
    align-items: center;
  }
  &-actions {
    display: flex;
    align-items: center;
    flex: 1;
  }
  &-tab {
    font-size: 28px;
    font-weight: 900;
    line-height: 56px;
    letter-spacing: 0px;
    text-align: left;
    background: transparent;
    color: $c-text-low;
    color: var(--c-text-low);
    margin-right: 32px;
    padding: 0;
    &:first-child {
      margin-left: 32px;
    }
    &.active {
      color: $c-text-high;
      color: var(--c-text-high);
    }
  }
  &-control {
    background: transparent;
    display: flex;
    align-items: center;
    margin-right: 16px;

    path {
      fill: $fg;
      fill: var(--fg);
    }
  }
  &-search {
    flex: 1;
    margin-right: 32px;
    display: flex;
    align-items: center;
    &_input {
      height: 32px;
      border-radius: 0px;
      background: $input-bg;
      background: var(--input-bg);
      border: 0;
      padding: 0 8px;
      display: block;
      width: 100%;
    }
    &_clear {
      width: 32px;
      height: 32px;
    }
  }
  &-bubble {
    height: 39px;
    width: 39px;
    border-radius: 28px;
    background: #bbbcbc;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: 6px;
    text-align: center;
    color: white;
    margin-right: 16px;
    padding: 0;
    &.active {
      background: $primary;
      background: var(--primary);
    }
  }
}

@media screen and (max-width: 667px) {
  .app__header {
    height: auto;
    min-height: auto;
    flex-direction: column;
    align-items: flex-start;
    &-actions {
      width: 100%;
      margin: 8px 0;
      justify-content: center;
    }
    &-search {
      margin-left: 16px;
      margin-right: 16px;
      flex-wrap: wrap;
    }
    &-bubble,
    &-control {
      margin-right: 4px;
    }
    &-tabs {
      width: 100%;
      border-bottom: 1px solid $border;
      border-bottom: 1px solid var(--border);
    }
    &-tab {
      margin-left: 8px;
      margin-right: 8px;
      font-size: 2.5rem;
      &:first-child {
        margin-left: 18px;
      }
    }
    &-logo {
      margin-left: 16px;
      margin-right: 8px;
    }
  }
}
