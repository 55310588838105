.app__files {
  cursor: pointer;
  position: relative;
  display: inline-block;

  > input {
    cursor: pointer;
    outline: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    overflow: hidden;
    filter: alpha(opacity=0); /* IE < 9 */
    opacity: 0;
  }
}
