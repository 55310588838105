.p {
  &-0 {
    padding: 0px;
  }
  &-1 {
    padding: 4px;
  }
  &-2 {
    padding: 8px;
  }
  &-3 {
    padding: 16px;
  }
  &-4 {
    padding: 32px;
  }
}
.px {
  &-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
  &-1 {
    padding-left: 4px;
    padding-right: 4px;
  }
  &-2 {
    padding-left: 8px;
    padding-right: 8px;
  }
  &-3 {
    padding-left: 16px;
    padding-right: 16px;
  }
  &-4 {
    padding-left: 32px;
    padding-right: 32px;
  }
}
.py {
  &-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  &-1 {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  &-2 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  &-3 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  &-4 {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}
.pt {
  &-0 {
    padding-top: 0px;
  }
  &-1 {
    padding-top: 4px;
  }
  &-2 {
    padding-top: 8px;
  }
  &-3 {
    padding-top: 16px;
  }
  &-4 {
    padding-top: 32px;
  }
}
.pb {
  &-0 {
    padding-bottom: 0px;
  }
  &-1 {
    padding-bottom: 4px;
  }
  &-2 {
    padding-bottom: 8px;
  }
  &-3 {
    padding-bottom: 16px;
  }
  &-4 {
    padding-bottom: 32px;
  }
}
.pr {
  &-0 {
    padding-right: 0px;
  }
  &-1 {
    padding-right: 4px;
  }
  &-2 {
    padding-right: 8px;
  }
  &-3 {
    padding-right: 16px;
  }
  &-4 {
    padding-right: 32px;
  }
}
.pl {
  &-0 {
    padding-left: 0px;
  }
  &-1 {
    padding-left: 4px;
  }
  &-2 {
    padding-left: 8px;
  }
  &-3 {
    padding-left: 16px;
  }
  &-4 {
    padding-left: 32px;
  }
}

.m {
  &-0 {
    margin: 0px;
  }
  &-1 {
    margin: 4px;
  }
  &-2 {
    margin: 8px;
  }
  &-3 {
    margin: 16px;
  }
  &-4 {
    margin: 32px;
  }
}
.mx {
  &-0 {
    margin-left: 0px;
    margin-right: 0px;
  }
  &-1 {
    margin-left: 4px;
    margin-right: 4px;
  }
  &-2 {
    margin-left: 8px;
    margin-right: 8px;
  }
  &-3 {
    margin-left: 16px;
    margin-right: 16px;
  }
  &-4 {
    margin-left: 32px;
    margin-right: 32px;
  }
}
.my {
  &-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  &-1 {
    margin-top: 4px;
    margin-bottom: 4px;
  }
  &-2 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  &-3 {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  &-4 {
    margin-top: 32px;
    margin-bottom: 32px;
  }
}
.mt {
  &-0 {
    margin-top: 0px;
  }
  &-1 {
    margin-top: 4px;
  }
  &-2 {
    margin-top: 8px;
  }
  &-3 {
    margin-top: 16px;
  }
  &-4 {
    margin-top: 32px;
  }
}
.mb {
  &-0 {
    margin-bottom: 0px;
  }
  &-1 {
    margin-bottom: 4px;
  }
  &-2 {
    margin-bottom: 8px;
  }
  &-3 {
    margin-bottom: 16px;
  }
  &-4 {
    margin-bottom: 32px;
  }
}
.mr {
  &-0 {
    margin-right: 0px;
  }
  &-1 {
    margin-right: 4px;
  }
  &-2 {
    margin-right: 8px;
  }
  &-3 {
    margin-right: 16px;
  }
  &-4 {
    margin-right: 32px;
  }
}
.ml {
  &-0 {
    margin-left: 0px;
  }
  &-1 {
    margin-left: 4px;
  }
  &-2 {
    margin-left: 8px;
  }
  &-3 {
    margin-left: 16px;
  }
  &-4 {
    margin-left: 32px;
  }
}
