@import '../../../assets/styles/foundation/variables';

.app__notes {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 8px;
  position: relative;
  &--input {
    font-family: $fontPrimary;
    border-radius: 0;
    margin-bottom: 10px;
    width: 100%;
    flex: 1;
    box-sizing: border-box;
    border: 1px solid transparent;
    line-height: 22px;
    padding: 8px;
    resize: none;
    appearance: none;
  }
}

// Loader
$loader-size: 10rem;
$animation-duration: 1.5s;

.loading {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: $loader-size;
  height: $loader-size;
}

.double-pulse {
  position: absolute;
  top: 0;
  left: 0;
  width: $loader-size;
  height: $loader-size;
  margin: 0 auto;
  border-radius: 100%;
  background-color: white;
  opacity: 0.4;
  animation: double-pulse $animation-duration infinite ease-out;

  &:nth-of-type(2) {
    animation-delay: -350ms;
  }
}

@keyframes double-pulse {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}
