@font-face {
  font-family: 'Untitled';
  src: url('SuisseIntl-Book.woff2') format('woff2'),
    url('SuisseIntl-Book.woff') format('woff'),
    url('SuisseIntl-Book.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Untitled';
  src: url('SuisseIntl-Black.woff2') format('woff2'),
    url('SuisseIntl-Black.woff') format('woff'),
    url('SuisseIntl-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Untitled';
  src: url('SuisseIntl-Bold.woff2') format('woff2'),
    url('SuisseIntl-Bold.woff') format('woff'),
    url('SuisseIntl-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Untitled';
  src: url('SuisseIntl-Medium.woff2') format('woff2'),
    url('SuisseIntl-Medium.woff') format('woff'),
    url('SuisseIntl-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Untitled';
  src: url('SuisseIntl-Regular.woff2') format('woff2'),
    url('SuisseIntl-Regular.woff') format('woff'),
    url('SuisseIntl-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Untitled';
  src: url('SuisseIntl-Light.woff2') format('woff2'),
    url('SuisseIntl-Light.woff') format('woff'),
    url('SuisseIntl-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Untitled';
  src: url('SuisseIntl-Thin.woff2') format('woff2'),
    url('SuisseIntl-Thin.woff') format('woff'),
    url('SuisseIntl-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Untitled';
  src: url('SuisseIntl-Ultralight.woff2') format('woff2'),
    url('SuisseIntl-Ultralight.woff') format('woff'),
    url('SuisseIntl-Ultralight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Untitled';
  src: url('SuisseIntl-Semibold.woff2') format('woff2'),
    url('SuisseIntl-Semibold.woff') format('woff'),
    url('SuisseIntl-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
