.btn-animate .ripple {
  animation: rippleAnimation 0.65s linear;
}
.ripple.animate {
  animation: rippleAnimation 0.65s linear;
}

.ripple {
  display: block;
  position: absolute;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 100%;
  transform: scale(0);
}

body.dark-theme {
  .ripple {
    background: rgba(0, 0, 0, 0.1);
  }
}

@-webkit-keyframes rippleAnimation {
  100% {
    opacity: 0;
    transform: scale(2.5);
  }
}

@keyframes rippleAnimation {
  100% {
    opacity: 0;
    transform: scale(2.5);
  }
}
