@import '../../../assets/styles/foundation/variables';

.app__switch {
  position: relative;
  margin: 0;
  padding: 0;
  width: 34px;
  height: 18px;
  cursor: pointer;
  display: inline-block;
  max-width: 100%;
  font-weight: 700;
}
.app__switch input[type='checkbox'] {
  display: none;
}
.app__switch .sw-pan {
  display: inline-block;
  width: inherit;
  height: inherit;
  background: $muted;
  background: var(--muted);
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
}
.app__switch .sw-btn {
  display: inline-block;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 1px;
  left: 1px;
  background: $bg;
  background: var(--bg);
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
}
.app__switch input:checked ~ .sw-btn {
  left: 17px;
  background: #adadad;
}
.app__switch input:checked ~ .sw-pan {
  opacity: 0.5;
  background: #adadad;
}
.app__switch input:disabled ~ .sw-btn,
.app__switch input:disabled ~ .sw-pan {
  opacity: 0.5;
  cursor: not-allowed;
}
.sw-default input:checked ~ .sw-btn {
  background: #adadad;
}
.sw-default input:checked ~ .sw-pan {
  background: #adadad;
}
.sw-primary input:checked ~ .sw-btn {
  background: $bg;
  background: var(--bg);
}
.sw-primary input:checked ~ .sw-pan {
  opacity: 1;
  background: $primary;
  background: var(--primary);
}
.sw-success input:checked ~ .sw-btn {
  background: #5cb85c;
}
.sw-success input:checked ~ .sw-pan {
  background: #5cb85c;
}
.sw-info input:checked ~ .sw-btn {
  background: #5bc0de;
}
.sw-info input:checked ~ .sw-pan {
  background: #5bc0de;
}
.sw-warning input:checked ~ .sw-btn {
  background: #f0ad4e;
}
.sw-warning input:checked ~ .sw-pan {
  background: #f0ad4e;
}
.sw-danger input:checked ~ .sw-btn {
  background: #d9534f;
}
.sw-danger input:checked ~ .sw-pan {
  background: #d9534f;
}
