@import '../../../assets/styles/foundation/variables';

.app__range {
  display: flex;
  align-items: center;

  input[type='range'] {
    -webkit-appearance: none;
    margin: 5px 0;
    height: 3px;
    width: 100%;
    background: transparent;
    background-color: transparent;
  }
  input[type='range']:focus {
    outline: none;
  }
  input[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: 3px;
    border-radius: 3px;
    cursor: pointer;
    animate: 0.2s;
  }
  input[type='range']::-webkit-slider-thumb {
    // border: 1px solid #adadad;
    height: 9px;
    width: 9px;
    background: $fg;
    background: var(--fg);
    border-radius: 6px;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -3px;
  }

  input[type='range']::-moz-range-track {
    width: 100%;
    height: 3px;
    border-radius: 3px;
    cursor: pointer;
    animate: 0.2s;
    background: $fg;
    background: var(--fg);
  }
  input[type='range']::-moz-range-thumb {
    height: 9px;
    width: 9px;
    background: #000000;
    border-radius: 6px;
    cursor: pointer;
  }
  input[type='range']::-ms-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
  }
  input[type='range']::-ms-fill-lower {
    background: #000000;
  }
  input[type='range']::-ms-fill-upper {
    background: #000000;
  }
  input[type='range']::-ms-thumb {
    height: 9px;
    width: 9px;
    background: #000000;
    border-radius: 6px;
    cursor: pointer;
  }
  input[type='range']:focus::-ms-fill-lower {
    background: #000000;
  }
  input[type='range']:focus::-ms-fill-upper {
    background: #000000;
  }
}
